// Import
import { createApp, defineAsyncComponent } from "vue"

// Components
import VueGoogleMaps from '@fawmi/vue-google-maps'

// Styles
import Vuex from 'vuex'

// Declare
declare const window: any;

// App main
const main = async () => {
    const app = createApp({
        delimiters: ['${', '}'],
        components: {
			SiteMenu: defineAsyncComponent(() => import(/* webpackChunkName: "SiteMenu" */ '@/js/components/SiteMenu.vue')),
			GridView: defineAsyncComponent(() => import(/* webpackChunkName: "GridView" */ '@/js/components/GridView.vue')),
			MembersMap: defineAsyncComponent(() => import(/* webpackChunkName: "MembersMap" */ '@/js/components/MembersMap.vue')),
        },
        data: () => ({}),
        methods: {

        },
        mounted() {
       },
    });

	// Use
	app.use(VueGoogleMaps, {
		load: {
			key: 'AIzaSyC_ghV3bXkdgqKCGKZW54rFj_evedYrs6o',
		},
	})


    // Mount the app
    const vm = app.mount('#app');
    return vm;
};

// Execute async function
main().then((vm) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
